<button
  (click)="click()"
  *ngIf="isRaised"
  mat-raised-button
  [color]="color"
  [type]="type"
  [disabled]="isLoading || disabled"
  class="d-flex justify-content-center align-items-center"
>
  <span *ngIf="!isLoading">
    {{ title }}
  </span>
  <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
</button>
<button
  (click)="click()"
  *ngIf="isStroked"
  mat-stroked-button
  [color]="color"
  [type]="type"
  [disabled]="isLoading || disabled"
  class="d-flex justify-content-center align-items-center"
>
  <span *ngIf="!isLoading">
    {{ title }}
  </span>
  <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
</button>

<!-- Only for link -->
<a
  (click)="click()"
  *ngIf="isLink"
  mat-button
  [color]="color"
  [type]="type"
  [disabled]="isLoading || disabled"
  class="d-flex justify-content-center align-items-center"
  [routerLink]="href"
>
  <span *ngIf="!isLoading">
    {{ title }}
  </span>
  <mat-spinner *ngIf="isLoading" [diameter]="20"></mat-spinner>
</a>
