<img *ngIf="image" [src]="image" class="img-fluid custom-img-icon" alt="" />

<div id="outerContainer">
  <div class="pdf-container">
    <pdf-viewer *ngIf="pdf"
      [src]="pdf"
      [rotation]="0"
      [original-size]="false"
      [show-all]="true"
      [fit-to-page]="false"
      [zoom]="1"
      [zoom-scale]="'page-width'"
      [stick-to-page]="false"
      [render-text]="true"
      [external-link-target]="'blank'"
      [autoresize]="true"
      [show-borders]="true"
      style="width: 75%; height: 600px;"
    ></pdf-viewer>
  </div>
</div>
<h1>{{ message }}</h1>
