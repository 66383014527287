import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input('color') color: string = 'primary';
  @Input('title') title!: string;
  @Input('type') type: 'button' | 'menu' | 'reset' | 'submit' = 'button';
  @Input('style') style: 'stroked' | 'raised' | 'link' = 'raised';
  @Input('toggle') toggle: Subject<any> = new Subject<any>();
  @Input('disabled') disabled: boolean = false;
  @Input('showLoader') showLoader: boolean = false;
  
  // this property will only work with style = link
  @Input('href') href: string | null = '';

  isLoading: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.toggle.subscribe(() => {
      this.toggleLoadingState();
    });
  }

  get isStroked() {
    return this.style === 'stroked';
  }

  get isRaised() {
    return this.style === 'raised';
  }

  get isLink() {
    return this.style === 'link';
  }

  /**
   * On button click
   */
  click = () => {
    setTimeout(() => {
      this.toggleLoadingState();
    }, 0);
  };

  /**
   * Toggle loading state
   */
  toggleLoadingState = () => {
    if (!this.showLoader) return;
    this.isLoading = !this.isLoading;
  };
}
