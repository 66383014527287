import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { randexp } from 'randexp';
declare var $: any;

@Directive({
  selector: '[appJqDatepicker]',
})
export class JqDatepickerDirective {
  @Input('controlName') controlName!: string;
  @Input('dateRange') dateRange!: any;

  elementId: string = '';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private group: FormGroupDirective,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.generateId();
    this.renderer.appendChild(this.elementRef.nativeElement, this.inputEl);
    this.jqueryDatePickedHandler();
  }

  jqueryDatePickedHandler = () => {
    const that = this;
    $(function () {
      const sDate = that.dateRange?.sDate?.split('-');
      const eDate = that.dateRange?.eDate?.split('-');
      $('#' + that.elementId + '~img.ui-datepicker-trigger').click(() => {
        $('#' + that.elementId).datepicker({
          dateFormat: 'mm-dd-yy',
          showOtherMonths: true,
          selectOtherMonths: true,
          changeMonth: true,
          changeYear: true,
          yearRange: '1900:2100',
          maxDate:
            that.dateRange?.eDate && new Date(eDate[2], eDate[0] - 1, eDate[1]),
          minDate:
            that.dateRange?.sDate && new Date(sDate[2], sDate[0] - 1, sDate[1]),
          onClose: (date: string, inst: object) => {
            $('#ui-datepicker-div').remove();
            $('#ui-datepicker-div').remove();
          },
        });
        $('#' + that.elementId).datepicker('show');
      });
      $('#' + that.elementId).change((e: any) => {
        if (that.group.form.controls[that.controlName]) {
          that.group.form.controls[that.controlName].setValue(e.target.value);
          that.group.form.markAsDirty();
        } else {
          $('#' + that.controlName).val(e.target.value);
          $('#' + that.controlName).trigger('click');
        }
      });
    });
  };

  get inputEl() {
    const child = this.document.createElement('input');
    child.id = this.elementId;
    child.hidden = true;
    return child;
  }

  get control() {
    return this.group.form.controls[this.controlName];
  }

  /**
   * Generate random id
   */
  generateId = () => {
    this.elementId = randexp(/[A-Za-z0-9]{15}/);
  };
}
