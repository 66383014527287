import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search',
})
export class SearchPipe implements PipeTransform {
  transform(value: Array<any>, ...args: unknown[]): Array<any> {
    const items = value || [];
    const titleKey = args[0] as string;
    const searchText = args[1] as string;
    return items.filter((item: any) => {
      return new RegExp(
        searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        'i'
      ).test(item[titleKey]);
    });
  }
}
