import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAllowedPattern]',
})
export class AllowedPatternDirective {
  @Input('inputPattern') inputPattern: string = '';

  constructor() {}

  @HostListener('keypress', ['$event']) keypress(event: any) {
    return new RegExp(this.inputPattern).test(event.key);
  }
}
