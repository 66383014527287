import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent implements OnInit {
  @Input('form') form!: FormGroup;
  @Input('label') label!: string;
  @Input('controlName') controlName: string = '';
  @Input('rows') rows: number = 4;
  @Input('disabled') disabled: boolean = false;
  required = false;

  constructor() {}

  ngOnInit(): void {
    if (this.control?.errors?.required === true) this.required = true;
  }

  get control() {
    return this.form?.controls[this.controlName];
  }

  get isRequired() {
    return this.control.hasValidator(Validators.required);
  }
}
