import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appRequired]',
})
export class RequiredDirective implements OnInit {
  @Input('controlName') controlName: string = '';
  @Input('dependsOn') dependsOn: string = '';

  private isMarked: boolean = false;
  private element: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private group: FormGroupDirective,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    if (!this.controlName || !this.dependsOn) return;
    this.group.form.controls[this.dependsOn].valueChanges.subscribe((val) => {
      this.setState(val);
    });
    this.setState(this.group.form.controls[this.dependsOn].value)
  }

  setState = (val: any) => {
    if (val === true || val === 'Yes' || (val || "").length > 0) this.markAsRequired();
    else this.removeRequired();
  }

  markAsRequired = () => {
    if (this.isMarked) return;
    this.isMarked = true;
    this.renderer.appendChild(
      this.elementRef.nativeElement,
      this.requiredElement
    );
  };

  removeRequired = () => {
    if (!this.element) return;
    this.isMarked = false;
    this.renderer.removeChild(this.elementRef.nativeElement, this.element);
  };

  get requiredElement() {
    const child = this.document.createElement('span');
    child.className = 'rqrd';
    child.innerHTML = ' *';
    this.element = child;
    return child;
  }

  get control() {
    return this.group.form.controls[this.controlName];
  }

  get isRequired() {
    const validator = this.control?.validator?.({} as AbstractControl);
    return validator && validator.required;
  }
}
