import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAddressOnly]',
})
export class AddressOnlyDirective {
  constructor() {}
  /**
   * created to select alphabets, numbers, period, comma and space and reject everything else
   */
  @HostListener('keypress', ['$event']) keypress(event: any) {
    return /[A-Za-z0-9.,#\s]/.test(event.key);
  }
}
