import { FormGroup } from "@angular/forms";
import { TableColumn } from "@swimlane/ngx-datatable";

/**
 * Shared components models
 */
export interface Input {
  type: string;
  label: string;
  placeholder: string;
  control: string;
}

export interface FormFields {
  form: FormGroup;
  rows: Array<FormFieldRow>;
}

/**
 * Input types
 */
export enum InputTypes {
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  SELECT = "SELECT",
}

/**
 * FormField row
 */
export interface FormFieldRow {
  class: string;
  fields: Array<GeneralFields>;
}

/**
 * Form Input
 */
export interface FormInput {
  type?: string;
}

/**
 * Form Select
 */
export interface FormSelect extends FormInput {
  option?: Array<any>;
  valueKey?: string;
  displayKey?: string;
}

/**
 * Form Textarea
 */
export interface GeneralFields extends FormSelect {
  inputType?: InputTypes;
  error?: string;
  controlName?: string;
  label?: string;
  class?: string;
}

/**
 * Drag upload
 */
export interface DragUpload {
  valid: boolean;
  invalid: boolean;
  value: Array<DragUploadValue>;
}

export interface DragUploadValue {
  name: string;
  value: string;
}

export interface DatatableColumn extends TableColumn {
  name: string;
  prop: string;
  fieldName: string;
  fieldType?: string;
  lookupTargetFieldId?: number | null;
  IsSummaryField?: number;
  IsUrlField?: number;
}

export class Page {
  // The number of elements in the page
  size: number = 0;
  // The total number of elements
  totalElements: number = 0;
  // The total number of pages
  totalPages: number = 0;
  // The current page number
  pageNumber: number = 0;
}

export interface ConditionKeys {
  FieldId: string;
  QueryCondition: string;
  Value: string;
  ConditionType: string;
  Conditions: string;
}
