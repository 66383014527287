import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { environment } from 'src/environments/environment';

/**
 * Stores to local storage with AES
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private ACCESS_TOKEN_KEY: string = 'token';
  private REFRESH_TOKEN_KEY: string = 'refresh';
  private SECRET: string = environment.secretKey;

  constructor() {}

  /**
   * Encrypt data
   * @param rawData can be anything object, string etc
   */
  private encrypt = (rawData: any) =>
    AES.encrypt(rawData, this.SECRET).toString();

  /**
   * Decrypt data
   * @param cipherData ciphered data
   */
  private decrypt = (cipherData: any): any | null => {
    try {
      if (cipherData !== null && cipherData !== undefined)
        return AES.decrypt(cipherData, this.SECRET).toString(enc.Utf8);
    } catch (e) {
      return null;
    }
    return null;
  };

  /**
   * Set item to storage with encryption
   * @param key of data
   * @param data to store
   */
  private setStorageItem = (key: string, data: string) => {
    localStorage.setItem(key, this.encrypt(data));
  };

  /**
   * Get item to storage with decryption
   * @param key of data
   */
  private getStorageItem = (key: string): string | null =>
    this.decrypt(localStorage.getItem(key));

  /**
   * Save token
   * @param token
   */
  saveToken = (token: string, refresh: string) => {
    this.setStorageItem(this.ACCESS_TOKEN_KEY, token);
    this.setStorageItem(this.REFRESH_TOKEN_KEY, refresh);
  };

  /**
   * Clear stored tokens
   */
  clearTokens = () => {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  };

  /**
   * Clear all
   */
  clear = () => {
    localStorage.clear();
  };

  /**
   * Get access token
   */
  get accessToken() {
    return this.getStorageItem(this.ACCESS_TOKEN_KEY);
  }

  /**
   * Get refresh token
   */
  get refreshToken() {
    return this.getStorageItem(this.REFRESH_TOKEN_KEY);
  }
}
