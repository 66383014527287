import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss", "../form-fields.component.scss"],
})
export class InputComponent implements OnInit {
  @Input("form") form!: any;
  @Input("label") label!: string;
  @Input("placeholder") placeholder: string = "Enter here";
  @Input("type") type: string = "text";
  @Input("requiredMessage") requiredMessage: string = "This field is required";
  @Input("invalidMessage") invalidMessage: string = "This field is invalid";
  @Input("matchMessage") matchMessage: string = "";
  @Input("controlName") controlName: string = "";
  @Input("disabled") disabled: boolean = false;
  @Input("mask") mask: string = "";
  @Input("prefix") prefix: string = "";
  @Input("piiData") piiData: any;
  @Input() requiredMark: boolean = false;
  @Input("minRequired") minRequired : boolean = false;
  @Input("minValue") minValue:number = 0;
  PIIData: any = {};
  required = false;
  minValueErrorMsg = `Value should be greater than ${this.minValue}`;
  minLengthErrorMsg = 'Minimum length is ';
  maxLengthErrorMsg = 'Maximum length is ';

  constructor() {}

  ngOnInit(): void {
    if (this.control?.errors?.required === true) this.required = true;
    setTimeout(() => {
      if (this.disabled) {
        this.control.clearValidators();
        this.control.updateValueAndValidity();
      }
    }, 0);
  }

  get control() {
    return this.form?.controls[this.controlName];
  }

  /**
   * Toggle text type from password to text and vice versa
   * This only happens if 'password' is passed to 'type' in first place
   */
  toggleType = (el: HTMLInputElement) => {
    if (el.type === "password") el.type = "text";
    else el.type = this.type;
  };

  /**
   * Set errors here priority wise
   * @return error message
   */
  getErrorMessage = () => {
    if (this.control?.errors?.required === true) this.required = true;
    if (this.control.hasError("mustMatch")) return this.matchMessage;
    if (this.control.hasError('minlength')) return this.minLengthErrorMsg + this.control?.errors?.minlength?.requiredLength + " characters";
    if (this.control.hasError('maxlength')) return this.maxLengthErrorMsg + this.control?.errors?.maxlength?.requiredLength + " characters";
    if (this.control.hasError("min")) return this.minValueErrorMsg;
    if (!this.control.hasError("required")) return this.invalidMessage;
    return this.requiredMessage;
  };

  /**
   * Set value for PII field according to change on focus
   */
  onInputFocus = () => {
    if (this.piiData != null) {
      if (
        this.PIIData[this.piiData.Name] == undefined ||
        this.PIIData[this.piiData.Name] == this.form.controls["Value"].value
      ) {
        this.PIIData[this.piiData.Name] = this.form.controls["Value"].value;
        this.form.controls["Value"].setValue("");
      }
    }
  };

  /**
   * Set value for PII field according to change on blur
   */
  onInputBlur = () => {
    if (this.piiData != null) {
      if (
        this.PIIData[this.piiData.Name] != undefined &&
        this.form.controls["Value"].value == ""
      ) {
        this.form.controls["Value"].setValue(this.PIIData[this.piiData.Name]);
      }
    }
  };

  /**
   * Check if control has required validator
   */
  get isRequired() {
    return this.control.hasValidator(Validators.required);
  }
}
