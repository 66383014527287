import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from "@angular/router";
import { forkJoin, zip } from "rxjs";
import { filter, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RouteService {
  paramMap: any = {};
  queryParams: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private title: Title
  ) {
    this.change()
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let route = this.route;
        while (route.firstChild) route = route.firstChild;
        route.params.subscribe((params) => {
          this.paramMap = { ...params, ...route.parent?.snapshot.params };
        });
        route.queryParams.subscribe((query) => {
          this.queryParams = {
            ...query,
            ...route.parent?.snapshot.queryParams,
          };
        });
      });
  }

  /**
   * Router change event
   * @returns observable
   */
  change = () =>
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

  /**
   * @returns record id from URL
   */
  get recordId() {
    return Number(this.paramMap.tableId);
  }

  /**
   * Current Route
   * @returns url
   */
  currentRoute = () => this.router.url;

  /**
   * By default Angular doesn't reloads if params changes
   * /app/122/345    ->     /app/122/432
   * In this case Angular doesn't opens new route in order to reuse it
   * we need to forcefully do that
   * @param initFunction initializing function
   */
  fork = (initFunction: Function) =>
    this.change().subscribe(() => {
      initFunction();
    });

  /**
   * Navigate to URL
   * @param commands array if routes
   * @returns navigate object
   */
  navigate = (commands: any[], extras?: NavigationExtras) => {
    if (
      this.paramMap.uid &&
      commands
        .map((e) => String(e).replace("/", ""))
        .some((e: any) => e === "application")
    )
      return this.router.navigate(
        [
          "/applicant",
          this.paramMap.uid,
          commands[0].replace("/", ""),
          ...commands.splice(1),
        ],
        extras
      );
    return this.router.navigate(commands, extras);
  };

  /**
   * One level up
   */
  back = () => this.location.back();

  /**
   * Change title of page
   * @param title to set
   */
  changeTitle = (title: string) => {
    this.title.setTitle(title);
  };
  /**
   * Make sure on each url defined, id of application should be defined under "tableId"
   * @returns table id from URL
   */
  getTableId = () => Number(this.paramMap.tableId);

  /**
   * Make sure on each url defined, id of application should be defined under "appId"
   * Look application.routing.ts
   * @returns Application id
   */
   getApplicationId = () => {
    return Number(this.paramMap.appId);
  };

  /**
   * Use redirect if want to load current route but with
   * different URL params (See Report Details component for implementation)
   * @param uri
   */
   redirect = (uri: string) => {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  };
}
