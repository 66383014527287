import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appNameOnly]',
})
export class NameOnlyDirective {
  constructor() {}
  /**
   * created to select alphabets, numbers and space and reject everything else
   */
  @HostListener('keypress', ['$event']) keypress(event: any) {
    return /[A-Z0-9\s]/.test(event.key);
  }
}