import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { saveAs } from "file-saver";
import { filter, take } from "rxjs/operators";
import { ReportService } from "../../services/report.service";

@Component({
  selector: "app-file-view",
  templateUrl: "./file-view.component.html",
  styleUrls: ["./file-view.component.scss"],
})
export class FileViewComponent implements OnInit {
  image: any = "";
  message: any = "";
  pdf: any = "";
  @Input("path") path: any;
  constructor(
    private report: ReportService,
    private activedRouter: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activedRouter.params.pipe(take(1)).subscribe((resp) => {
      if (resp && resp.tableId && resp.recordId && resp.fieldId) {
        this.getFile(
          Number(resp.tableId),
          Number(resp.recordId),
          Number(resp.fieldId)
        );
      }
    });
  }

  getFile(tableId: number, recordId: number, fieldId: number) {
    this.report
      .getFile(tableId, recordId, fieldId)
      .pipe(filter((data: any) => !!data && data.File != undefined))
      .subscribe((data) => {
        if (data.File != undefined) {
          if (data.FileType != undefined && data.FileType.includes("image"))
            this.image = data.File;
          else if (data.FileType != undefined && data.FileType.includes("pdf"))
            this.pdf = data.File;
          else {
            saveAs(data.File, data.FileName);
            this.message = data.FileName + " Download will begin shortly! ";
          }
        }
      });
  }
}
