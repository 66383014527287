import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

@Directive({
  selector: '[appEyeball]',
})
export class EyeballDirective implements OnInit {
  @Input('eyeImgStyle') style = "";
  defaultClass: string = '';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private group: FormGroupDirective,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.defaultClass =
      (this.elementRef.nativeElement as HTMLDivElement).querySelector(
        ':first-child'
      )?.className || '';
    (this.elementRef.nativeElement as HTMLDivElement).setAttribute(
      'style',
      'position: relative; max-width: 88px; width: 88px;'
    );
    this.renderer.appendChild(this.elementRef.nativeElement, this.inputEl);
  }

  get inputEl() {
    const child = this.document.createElement('img');
    child.src =
      '../../assets/main/css/themes/classic/images/icons/icon_eye_gray.png';
    child.setAttribute(
      'style',
      'position: absolute;top: 6px;right: 4px; cursor: pointer;' + this.style
    );
    child.onclick = () => {
      this.event();
    };
    return child;
  }

  event = () => {
    const element = this.elementRef.nativeElement.querySelector(':first-child');
    if (element.className.includes('password'))
      element.className = this.defaultClass.replace('password', '');
    else element.className = this.defaultClass + ' password';
  };
}
