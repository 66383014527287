<div class="modal-footer-bottom-row">
  <app-button
    class="mr-2"
    (onClick)="onReject.emit()"
    [title]="rejectTitle"
  ></app-button>
  <app-button
    type="submit"
    [disabled]="form?.invalid || false || disabled"
    [title]="confirmTitle"
    (onClick)="onConfirm.emit()"
  ></app-button>
</div>
