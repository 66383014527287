import { Injectable } from "@angular/core";
import * as moment from "moment";
import { CookieService } from "ngx-cookie";
import { environment } from "src/environments/environment";
import { IApplicationData } from "../models/application.models";
import { IProfile, IToken } from "../models/auth.models";
import { ApiService } from "./api.service";
import { ResolverService } from "./resolver.service";

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private applicationData: IApplicationData | null = null;
  tokenAPI: IToken | null = null;
  profile: IProfile | null = null;
  access_token: string | null = null;
  refresh_token: string | null = null;
  isLoggedIn: boolean = false;

  constructor(
    private api: ApiService,
    private resolver: ResolverService,
    private cookie: CookieService
  ) {}

  /**
   * find out field id in @tableName via @fieldName
   * @param tableName name of table
   * @param fieldName name of field
   * @param propertyToReturn pass 'all' to get whole object
   */
  findField = async (
    tableName: string,
    fieldName: string,
    propertyToReturn: string | "all" = "Id"
  ) => {
    await this.fetchApplicationData();
    if (!this.applicationData) throw new Error(`Application data is null`);
    const tableObject = this.applicationData.Tables.find(
      ({ Name }) => Name === tableName
    );
    if (!tableObject) throw new Error(`Table ${tableName} not found`);
    const fieldObject = tableObject.Fields.find(
      ({ Name }) => Name === fieldName
    );
    if (!fieldObject)
      throw new Error(`Field ${fieldName} not found in ${tableName}`);
    if (propertyToReturn === "all") return fieldObject;
    if (!(fieldObject as any)[propertyToReturn])
      throw new Error(`Property ${propertyToReturn} doesn't exists`);
    return (fieldObject as any)[propertyToReturn];
  };

  /**
   * find table
   * @param tableName table name to find
   * @param propertyToReturn property to return (pass 'all' if whole object is required)
   */
  findTable = async (
    tableName: string,
    propertyToReturn: string | "all" = "Id"
  ) => {
    await this.fetchApplicationData();
    if (!this.applicationData) throw new Error(`Application data is null`);
    const tableObject = this.applicationData.Tables.find(
      ({ Name }) => Name === tableName
    );
    if (!tableObject) throw new Error(`Table ${tableName} not found`);
    if (!(tableObject as any)[propertyToReturn])
      throw new Error(`Property ${propertyToReturn} doesn't exists`);
    return (tableObject as any)[propertyToReturn];
  };

  /**
   * find table
   * @param tableName table name to find
   * @param propertyToReturn property to return (pass 'all' if whole object is required)
   */
  findReport = async (
    tableName: string,
    reportName: string,
    propertyToReturn: string | "all" = "Id"
  ) => {
    await this.fetchApplicationData();
    if (!this.applicationData) throw new Error(`Application data is null`);
    const tableObject = this.applicationData.Tables.find(
      ({ Name }) => Name === tableName
    );
    if (!tableObject) throw new Error(`Table ${tableName} not found`);
    const reportObject = tableObject.Reports.find(
      ({ Name }) => Name === reportName
    );
    if (!reportObject)
      throw new Error(`Report ${reportName} not found in ${tableName}`);
    if (propertyToReturn === "all") return reportObject;
    if (!(reportObject as any)[propertyToReturn])
      throw new Error(`Property ${propertyToReturn} doesn't exists`);
    return (reportObject as any)[propertyToReturn];
  };

  /**
   * User email
   */
  profileEmail = async () => {
    await this.fetchProfile();
    return this.profile?.Email || "";
  };

  /**
   * User profile
   */
  getProfile = async () => {
    await this.fetchProfile();
    return this.profile;
  };

  /**
   * User Role
   */
  getRole = async () => {
    await this.fetchProfile();
    let profile = this.profile?.ApplicationRoles.find(
      (a) => a.ApplicationId === environment.applicationId
    );
    return profile ? profile.Name : "";
  };

  /**
   * Sets login state according to fetched data
   */
  cacheLoginState = (tokenAPIResponse: IToken) => {
    this.tokenAPI = tokenAPIResponse;
    this.access_token = this.tokenAPI!.access_token;
    this.refresh_token = this.tokenAPI!.refresh_token;
    this.cookie.put(
      "expires",
      moment(this.tokenAPI?.[".expires"]!).format("x")
    );
    this.isLoggedIn = true;
  };

  /**
   * Clear all cache
   */
  clearCache = () => {
    this.applicationData = null;
    this.profile = null;
    this.access_token = null;
    this.refresh_token = null;
    this.tokenAPI = null;
    this.isLoggedIn = false;
  };

  /**
   * fetch API functions
   * Returns cached if already exists
   */
  private fetchApplicationData = async () => {
    if (!this.access_token) return;
    if (this.applicationData) return Promise.resolve(this.applicationData);
    return (this.applicationData = await this.resolver.fetchApplicationData());
  };

  /**
   * fetch profile
   * Returns cached if already exists
   */
  private fetchProfile = async () => {
    if (!this.access_token) return;
    if (this.profile) return Promise.resolve(this.profile);
    return (this.profile = await this.resolver.fetchProfile());
  };
}
