import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RouteService } from "src/app/core/services/route.service";

@Component({
  selector: "app-show-info",
  templateUrl: "./show-info.component.html",
  styleUrls: ["./show-info.component.scss"],
})
export class ShowInfoComponent implements OnInit {
  @Input("timeToComplete") timeToComplete: string = "Approx. 1 Hour";
  @Input("processOverview") processOverview: string =
    "You will be able to save your progress as you complete portions of your application.";
  @Input("clarifyingNavigation") clarifyingNavigation: Array<string> = [
    "All questions must be completed before moving to the next section",
  ];
  @Input("overview") overview: Array<any> = [
    "Documents/information to collect before getting started",
  ];
  @Input("isIspGrantApplication") isIspGrantApplication : boolean = false;

  constructor(private modal: NgbModal, private route: RouteService) {}

  ngOnInit(): void {}

  close = () => {
    this.modal.dismissAll();
  };

  cancel = () => {
    this.close();
    this.route.back();
  };
}
