<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-left">
      <h4 class="modal-title">Columns</h4>
    </div>
    <div class="modal-header-right">
      <button type="button" class="close" (click)="close()">
        <i class="fe fe-x"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="modal-body-inner mb-3">
      <div class="d-flex mb-2 align-items-center">
        <h1>Available Columns</h1>
        <div class="btn-group ml-3">
          <button
            (click)="selectAll()"
            type="button"
            class="btn btn-sm btn-light"
          >
            Select All
          </button>
          <button
            (click)="unSelectAll()"
            type="button"
            class="btn btn-sm btn-light"
          >
            Unselect All
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-lg-4" *ngFor="let group of controls">
          <app-checkbox
            class="mr-3"
            [form]="asGroup(group)"
            controlName="checked"
            [label]="group.value.name | titlecase"
          ></app-checkbox>
        </div>
      </div>
    </div>
    <div class="modal-footer-bottom">
      <div class="modal-footer-bottom-row">
        <!-- <app-modal-action-btns
          confirmTitle="Save"
          (onReject)="close()"
          (onConfirm)="emit()"
        ></app-modal-action-btns> -->
        <button
          type="button"
          class="btn btn-cancel text-uppercase mr-10"
          (click)="close()"
        >
          Cancel
        </button>
        <button
          (click)="emit()"
          type="button"
          class="btn btn-common-primary text-uppercase"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</div>
