import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ResolverService {
  private applicationData: Promise<any> | null = null;
  private profile: Promise<any> | null = null;

  constructor(private api: ApiService) {}

  fetchApplicationData = async () => {
    if (!this.applicationData)
      this.applicationData = this.api
        .applicationdata()
        .pipe(tap(() => (this.applicationData = null)))
        .toPromise();
    return this.applicationData;
  };

  fetchProfile = async () => {
    if (!this.profile)
      this.profile = this.api
        .getProfile()
        .pipe(tap(() => (this.profile = null)))
        .toPromise();
    return this.profile;
  };
}
