import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appLettersWithApostrophe]',
})
export class LettersWithApostropheDirective {
  constructor() {}

  @HostListener('keypress', ['$event']) keypress(event: any) {
    return /[A-Za-z'\s]/.test(event.key);
  }
}
