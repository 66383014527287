import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.scss'],
})
export class ErrorPopupComponent implements OnInit {
  @Input('innerHTML') innerHTML: string | null = null;
  @Output('onClose') onClose = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
