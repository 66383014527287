<div *ngIf="form" [formGroup]="form" class="form-group form-group-icon">
  <label *ngIf="label">{{ label }}</label>
  <span *ngIf="isRequired && label" class="ml-1 mb-1 text-danger"> * </span>
  <div class="form-group-control d-flex">
    <input
      #el
      [mask]="mask"
      [prefix]="prefix"
      [type]="type"
      class="form-control"
      [placeholder]="placeholder"
      numbersOnly
      [isNumberOnly]="type === 'number'"
      [formControlName]="controlName"
      [ngClass]="{
        'is-invalid': control.dirty && control.errors,
        'color-input': type === 'color',
        'required-mark': requiredMark,
        input: disabled === true
      }"
      [attr.disabled]="disabled ? true : null"
      (focus)="onInputFocus()"
      (blur)="onInputBlur()"
      [attr.min]="minRequired ? minValue : null"
    />
    <span class="icon-group" *ngIf="type === 'password'">
      <button
        (click)="toggleType(el)"
        type="button"
        class="btn pass-hide password-view-click"
        [ngClass]="{ 'eye-icon-offset': control.dirty && control.errors }"
      >
        <span
          class="fe pass-custom-icon"
          [ngClass]="el.type !== 'password' ? 'fe-eye-off' : 'fe-eye'"
        ></span>
      </button>
    </span>
  </div>
  <div *ngIf="control.dirty && control.errors" class="info-box error-box">
    <span class="text-message text-danger"> {{ getErrorMessage() }}</span>
  </div>
</div>

<div *ngIf="!form" class="form-group form-group-icon">
  <label *ngIf="label">{{ label }}</label>
  <div class="form-group-control d-flex">
    <input
      [type]="type"
      class="form-control"
      [placeholder]="placeholder"
      [attr.disabled]="disabled ? true : null"
    />
  </div>
</div>
