<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-center">
      <h4 class="modal-title">Attention!</h4>
    </div>
  </div>
  <div class="modal-header" *ngIf="isIspGrantApplication">
    <div class="setdescription">
      <h4>ISP Grant Application Overview:</h4>
    </div>
  </div>
  <div class="modal-body ml-3">
    <div class="mb-2">
      <span>Time To Complete : {{ timeToComplete }}</span>
      <br />
    </div>
    <div class="mb-2">
      <span>Process Overview : {{ processOverview }}</span><br />
    </div>
    <div class="mb-2">
      <span>Clarifying Navigation :</span>
      <ul *ngFor="let item of clarifyingNavigation">
        <li>{{ item }}</li>
      </ul>
      <br />
    </div>
    <div class="mb-2">
      <span>Overview For Each Section :</span>
      <ul *ngFor="let item of overview">
        <li>
          {{ item?.headerName }}
          <ul *ngFor="let child of item?.childs" class="mx-4">
            <li>{{ child }}</li>
          </ul>
        </li>
      </ul>
      <br />
    </div>
  </div>
  <div
    class="modal-footer modal-footer-custom-new01 d-flex justify-content-between"
  >
    <div class="modal-footer-left">
      <button id="prev" class="btn alertButtonCancel" (click)="cancel()">
        Cancel
      </button>
    </div>
    <div class="modal-footer-right">
      <button
        type="button"
        class="btn alertButtonSuccess"
        (click)="close()"
        data-dismiss="modal"
        aria-label="Close"
      >
        Start
      </button>
    </div>
  </div>
</div>