import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-action-btns',
  templateUrl: './modal-action-btns.component.html',
  styleUrls: ['./modal-action-btns.component.scss'],
})
export class ModalActionBtnsComponent implements OnInit {
  @Input('form') form: FormGroup | FormArray | null = null;
  @Input('rejectTitle') rejectTitle: string = 'Cancel';
  @Input('confirmTitle') confirmTitle: string = 'Save';
  @Input('disabled') disabled: boolean = false;

  @Output('onReject') onReject = new EventEmitter();
  @Output('onConfirm') onConfirm = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
