import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loading: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Set loading state w/ timeout
   */
  set loadingState(state: boolean) {
    setTimeout(() => {
      this.loading = state;
      if (this.loading)
        this.document.body.setAttribute('style', 'cursor: wait;');
      else this.document.body.removeAttribute('style');
    }, 0);
  }

  get isLoading() {
    return this.loading;
  }
}
