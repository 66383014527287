import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDate]',
})
export class DateDirective {
  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    let e = <KeyboardEvent>event;
    if (e.key === 'Backspace' || e.key === 'Tab' || e.key === '-') return true;
    return /[0-9]/.test(e.key);
  }
}
