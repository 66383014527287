<div *ngIf="form" [formGroup]="form" class="form-group select2-form-group">
  <div class="d-flex mb-1">
    <label *ngIf="label" for="">{{ label }}</label>
    <span *ngIf="isRequired && label" class="text-danger ml-1"> * </span>
    <div *ngIf="canClear" class="select2-remove" (click)="clearSelect()">
      <i class="fe fe-x ml-2"></i> Remove
    </div>
  </div>
  <div class="select-box select-custom2 select-custom2-general">
    <select2
      *ngIf="!isOptionLoading()"
      class="js-select2 w-100"
      [multiple]="multiple"
      [formControlName]="controlName"
      [data]="getOptions()"
      [displaySearchStatus]="'always'"
      [placeholder]="getPlaceholder()"
      (update)="change($event)"
      [ngClass]="{
        'is-invalid': control.dirty && control.errors,
        'required-mark': requiredMark
      }"
    >
    </select2>
    <select2
      *ngIf="isOptionLoading()"
      class="js-select2 w-100"
      [displaySearchStatus]="'always'"
      [placeholder]="getPlaceholder()"
      disabled
    >
    </select2>
  </div>
  <div *ngIf="control.dirty && control.errors" class="info-box error-box">
    <span class="text-message text-danger"> {{ error }} </span>
  </div>
</div>

<div *ngIf="!form" class="form-group select2-form-group">
  <label *ngIf="label" for="">{{ label }}</label>
  <div class="select-box select-custom2 select-custom2-general">
    <select2
      *ngIf="!isOptionLoading()"
      class="js-select2 w-100"
      [multiple]="multiple"
      [data]="getOptions()"
      [displaySearchStatus]="'always'"
      [placeholder]="getPlaceholder()"
      (update)="change($event)"
    >
    </select2>
    <select2
      *ngIf="isOptionLoading()"
      class="js-select2 w-100"
      [displaySearchStatus]="'always'"
      [placeholder]="getPlaceholder()"
      disabled
    >
    </select2>
  </div>
</div>
