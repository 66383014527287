import { Injectable } from '@angular/core';
import { utils, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  constructor() {}

  /**
   * Export as excel
   */
  xlsx = (fileName: string, sheetName: string, data: Array<object>) => {
    const wb = utils.book_new();
    utils.book_append_sheet(wb, utils.json_to_sheet(data), sheetName);
    writeFile(wb, `${fileName}.xlsx`);
  };

  /**
   * Export as csv
   */
  csv = (fileName: string, data: Array<object>) => {
    saveAs(
      new Blob([utils.sheet_to_csv(utils.json_to_sheet(data))], {
        type: 'text/csv;charset=utf-8',
      }),
      `${fileName}.csv`
    );
  };
}
