import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ToastrService } from "ngx-toastr";
import alert, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

export enum ToastTypes {
  SUCCESS = "success",
  ERROR = "error",
}

export const successObject = {
  icon: "success",
  customClass: {
    confirmButton: "btn btn-success",
  },
  buttonsStyling: false,
};

export const alertPresets = {
  delete: {
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "alertButtonSuccess",
      cancelButton: "mr-3 alertButtonCancel",
    },
    reverseButtons: true,
    confirmButtonText: "Yes, delete it!",
    buttonsStyling: false,
  },
  successDelete: {
    title: "Deleted!",
    text: "Your record has been deleted.",
    ...successObject,
  },
  saveSuccess: {
    title: "Saved!",
    text: "Your changes have been saved.",
    ...successObject,
  },
  sendEmail: {
    title: "Send Email",
    text: "Email will be send to user for password recovery!",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "alertButtonSuccess",
      cancelButton: "mr-3 alertButtonCancel",
    },
    reverseButtons: true,
    confirmButtonText: "Yes, send it!",
    buttonsStyling: false,
  },
  moveToTab: {
    title: "Where to Begin",
    text: "Continue where you left off?",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "alertButtonSuccess",
      cancelButton: "mr-3 alertButtonCancel",
    },
    reverseButtons: true,
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    buttonsStyling: false,
  },
  confirmSave: {
    title: "Save",
    text: "Your changes are not saved. Do you want to save them now?",
    icon: "warning",
    showCancelButton: true,
    customClass: {
      confirmButton: "alertButtonSuccess",
      cancelButton: "mr-3 alertButtonCancel",
    },
    reverseButtons: true,
    confirmButtonText: "Save changes",
    cancelButtonText: "Discard changes and close",
    buttonsStyling: false,
  },
};

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private toastr: ToastrService) {}

  /**
   * Fire alert
   * @param alertObject
   */
  fire = (
    alertObject: SweetAlertOptions,
    extras: SweetAlertOptions = {}
  ): Promise<SweetAlertResult> => alert.fire({ ...alertObject, ...extras });

  /**
   * Toast
   */
  toast = (type: ToastTypes, title: string, message: string) => {
    this.toastr[type](message, title);
  };
}
