import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  IDropdownOptions,
  IConditionalQuery,
  IFormActionPost,
  IFormActionPut,
  Options,
  IFormActionDelete,
} from "../models/api.models";
import { IApplicationData } from "../models/application.models";
import { ILogin, IProfile, IToken } from "../models/auth.models";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private deleteURL = environment.apiURL + "formaction/deleteobject";
  private putURL = environment.apiURL + "formaction/putdata";
  private postURL = environment.apiURL + "formaction/postdata";
  private getURL = environment.apiURL + "report/queryreport";
  private loginURL = environment.apiURL + "user/login";
  private tokenURL = environment.apiURL.split("api/")[0] + "token";
  private appdataURL =
    environment.apiURL +
    "applicationdata?applicationId=" +
    environment.applicationKey;
  private customRegisterURL = environment.apiURL + "user/custom-register";
  private getAppUserAPI =
    environment.apiURL +
    "user/getapplicationusers?applicationId=" +
    environment.applicationKey;
  private profileURL = environment.apiURL + "user/getprofile";
  private updateProfileURL = environment.apiURL + "user/updateprofile";
  private logoutURL = environment.apiURL + "user/logout?token=";
  private dropdownOptionURL =
    environment.apiURL + "field/getdropdownvalues?id=";
  private getUserAPI =
    environment.apiURL +
    "user/getUsers?applicationId=" +
    environment.applicationId;
  private forgotPasswordURL = environment.apiURL + "user/forgotpassword";
  private recoveryURL = environment.apiURL + "user/recoverpassword";

  constructor(private http: HttpClient) {}

  /**
   * Basic form actions start
   */

  /**
   * Get data with conditions
   * @param conditionalQuery condition to filter
   */
  get = <T>(conditionalQuery: IConditionalQuery, options?: Options) =>
    this.http.post<Array<T>>(this.getURL, conditionalQuery).pipe(
      this.handleError(options),
      map((response: Array<any>) => {
        let tableData: Array<T> = [];
        for (let table of response) {
          let tableObject: any = {};
          for (let item in table) {
            let val = table[item];
            // if (val.)
            // const date = moment(val, 'MM/DD/YYYY', false);
            if (
              (val.match(/\//g) || []).length === 2 &&
              !new Date(val).toString().includes("Invalid")
            )
              val = moment(val, "MM/DD/YYYY", false).format("MM/DD/YYYY");
            if (item.indexOf("_") > -1 && item.substr(0, 3) === "tbl") {
              let key = item as string;
              while (key.includes("tbl")) {
                key = key.split("_").splice(1).join("_");
              }

              tableObject[key] = moment(val, "MM/DD/YYYY", true).isValid()
                ? moment(val, "MM/DD/YYYY", true).format("MM-DD-YYYY")
                : val;
            } else
              tableObject[item] = moment(val, "MM/DD/YYYY", true).isValid()
                ? moment(val, "MM/DD/YYYY", true).format("MM-DD-YYYY")
                : val;
          }
          tableData.push(tableObject);
          tableObject = {};
        }
        return tableData;
      })
    );

  /**
   * Update a record
   * @param payload data to update
   */
  put = (payload: IFormActionPut, options?: Options) =>
    this.http.put<Object>(this.putURL, payload).pipe(this.handleError(options));

  /**
   * Create a new record
   * @param payload data to insert
   */
  post = (payload: IFormActionPost, options?: Options) =>
    this.http
      .post<Object>(this.postURL, payload)
      .pipe(this.handleError(options));

  /**
   * Delete a record
   * @param payload data to delete
   */
  delete = (payload: IFormActionDelete, options?: Options) =>
    this.http
      .request("delete", this.deleteURL, { body: payload })
      .pipe(this.handleError(options));

  /**
   * Basic form actions end
   *
   * Auth API Start
   */
  login = (username: string, password: string,recaptchaV3token:string, options?: Options) => {
    let payload = new HttpParams();
    payload = payload.append("Email", username);
    payload = payload.append("PasswordHash", password);
    payload = payload.append("ApplicationId", environment.applicationKey);
    payload = payload.append(
      "GoogleRecaptchaTokenV3",
      recaptchaV3token
    );
    return this.http
      .post<ILogin>(this.loginURL, payload)
      .pipe(this.handleError(options));
  };

  /**
   * Token API
   */
  token = (username: string, password: string, options?: Options) => {
    let payload = new HttpParams();
    payload = payload.append("grant_type", "password");
    payload = payload.append("client_id", environment.client_id);
    payload = payload.append("username", username);
    payload = payload.append("password", password);
    return this.http
      .post<IToken>(this.tokenURL, payload)
      .pipe(this.handleError(options));
  };

  /**
   * Auth API End
   */

  /**
   * Load application data
   */
  applicationdata = (options?: Options) =>
    this.http
      .get<IApplicationData>(this.appdataURL)
      .pipe(this.handleError(options));

  /**
   * Custom register
   * @param email email to register
   * @param password corresponding password
   */
  customRegister = (
    email: string,
    password: string,
    roleKey: string,
    recaptchaV3token: string,
    options?: Options
  ) =>
    this.http
      .post<any>(this.customRegisterURL, {
        Email: email,
        Password: password,
        ApplicationRoleIds: [roleKey],
        ApplicationIds: [environment.applicationKey],
        GoogleRecaptchaTokenV3: recaptchaV3token
      })
      .pipe(this.handleError(options));

  /**
   * Get application users
   * @param query search via email
   */
  getApplicationUsers = (
    query: string | null = null,
    options?: Options
  ): Observable<Array<IProfile>> =>
    this.http
      .get<Array<IProfile>>(
        `${this.getAppUserAPI}${query ? `&query=${query}` : ""}`
      )
      .pipe(this.handleError(options));

  /**
   * Get current user profile
   */
  getProfile = (options?: Options) =>
    this.http.get<IProfile>(this.profileURL).pipe(this.handleError(options));

  /**
   * Update user profile
   */
  updateProfile = (payload: any, options?: Options) =>
    this.http
      .post<string>(this.updateProfileURL, payload)
      .pipe(this.handleError(options));

  /**
   * Logout user
   */
  logout = (refreshToken: string, options?: Options) =>
    this.http
      .get<any>(this.logoutURL + refreshToken)
      .pipe(this.handleError(options));

  /**
   * Get dropdown options
   */
  dropdownOptions = (fieldId: number, options?: Options) =>
    this.http
      .get<IDropdownOptions>(this.dropdownOptionURL + fieldId)
      .pipe(this.handleError(options));

  /**
   * Get users
   * @param query search via email
   */
  getUsers = (
    query: string | null = null,
    options?: Options
  ): Observable<Array<IProfile>> =>
    this.http
      .get<Array<IProfile>>(
        `${this.getUserAPI}${query ? `&query=${query}` : ""}`
      )
      .pipe(this.handleError(options));

  /**
   * Forgot Password
   * @param email email to register
   * @param password corresponding password
   */
  forgotPassword = (
    email: string,
    clientLocation: string = environment.deploymentURL,
    options?: Options
  ) =>
    this.http
      .post<any>(this.forgotPasswordURL, {
        email,
        clientLocation,
        newPassword: null,
        recoveryCode: null,
      })
      .pipe(this.handleError(options));

  /**
   * Recover Password
   * @param email email to recover
   * @param newPassword new password to set
   * @param recoveryCode code on mail
   * @param password corresponding password
   */
  recoverPassword = (
    email: string,
    newPassword: string,
    recoveryCode: string,
    clientLocation: string = environment.deploymentURL,
    options?: Options
  ) =>
    this.http
      .post<any>(this.recoveryURL, {
        email,
        clientLocation,
        newPassword,
        recoveryCode,
      })
      .pipe(this.handleError(options));

  /**
   * Handle API errors
   */
  private handleError = <T>(options?: Options) =>
    catchError<T, Observable<never>>((err: HttpErrorResponse) => {
      if (err) options?.onFailed?.(err);
      return throwError(!environment.production ? err : err.message);
    });
}
