import { ExportDataMapping } from "./models/application.models";

/**
 * Content types
 */
export const CONTENT_TYPES = {
  APPLICATION_JSON: "application/json",
  MULTIPART_FORMDATA: "multipart/form-data",
  URLENCODED: "application/x-www-form-urlencoded",
};

/**
 * Accepted password pattern
 */
export const PASSWORD_PATTERN =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#&^()_+])[A-Za-z\d@$!%*#&^()_+]{8,}$/;

// /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#&^()_+])[A-Za-z\d@$!%*#&^()_+]{8,}$/;
// /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
// /^(?=.[A-Z]+)(?=.[a-z]+)(?=.[0-9]+)(?=.[!@#$%^&()+])[A-Za-z\d][A-Za-z\d!@#$%^&()+]{7,50}$/

export class Constants {
  public static MESSAGES = {
    APPLICATION_CREATE_SUCCESS: "Application Created Successfully",
    APPLICATION_CREATE_ERROR: "Error in Creating Application",
    APPLICATION_UPDATE_SUCCESS: "Application Updated Successfully",
    APPLICATION_UPDATE_ERROR: "Error Updating Application",
    APPLICATION_FETCH_ERROR: "Error Fetching Application Data",
    PROPERTY_FETCH_ERROR: "Error Fetching Property Data",
    RENTAL_FETCH_ERROR: "Error Fetching Rental Data",
    EXPENSES_FETCH_ERROR: "Error Fetching Expenses Data",
    EXPENSES_UPDATE_SUCCESS: "Expenses Updated Successfully",
    EXPENSES_UPDATE_ERROR: "Error Updating Expenses",
    EXPENSE_DELETE_SUCCESS: "Expense Deleted Successfully",
    EXPENSE_DELETE_ERROR: "Error Deleting Expense",
    HOUSEHOLD_FETCH_ERROR: "Error Fetching Household Data",
    HOUSEHOLD_UPDATE_SUCCESS: "Household Updated Successfully",
    HOUSEHOLD_UPDATE_ERROR: "Error Updating Household",
    HOUSEHOLD_DELETE_SUCCESS: "Household Deleted Successfully",
    HOUSEHOLD_DELETE_ERROR: "Error Deleting Household",
    TIN_ALREADY_EXIST: "Error: SSN / ITIN / EIN Already Registered",
    INVALID_NAICS: "Error: Invalid NAICS Code",
    INVITATION_EMAIL_SUCCESS: "Invitation Email Sent Successfully",
    INVITATION_EMAIL_ERROR: "Error Sending Invite Email",
    VERIFICATION_MISMATCH: "Verification data does not match Renter data",
    VERIFICATION_NOT_CHECKED: "You did not certify this approval",
    CREATE_TICKET_SUCCESS: "Ticket Created Successfully",
    CREATE_TICKET_ERROR: "Error Creating Ticket",
    FILE_UPLOAD_SUCCESS: "File Uploaded Successfully",
    FILE_UPLOAD_ERROR: "Error Uploading File",
    PROPERTY_UPDATE_SUCCESS: "Property Updated Successfully",
    PROPERTY_UPDATE_ERROR: "Error Updating Property",
    FILE_DELETE_SUCCESS: "File Deleted Successfully",
    FILE_DELETE_ERROR: "Error Deleting File",
  };

  public static MONTHS_MAPPING = {
    "March 2020": "March 2020",
    "April 2020": "April 2020",
    "May 2020": "May 2020",
    "June 2020": "June 2020",
    "July 2020": "July 2020",
    "August 2020": "August 2020",
    "September 2020": "September 2020",
    "October 2020": "October 2020",
    "November 2020": "November 2020",
    "December 2020": "December 2020",
    "January 2021": "January 2021",
    "February 2021": "February 2021",
    "March 2021": "March 2021",
    "April 2021": "April 2021",
    "May 2021": "May 2021",
    "June 2021": "June 2021",
    "July 2021": "July 2021",
    "August 2021": "August 2021",
    "September 2021": "September 2021",
    "October 2021": "October 2021",
    "November 2021": "November 2021",
    "December 2021": "December 2021",
  };

  public static FILE_SIZE_LIMIT = 15 * 1000000; //(1 MB = 1000000 bytes)
  public static EMAIL_PATTERN =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static AMOUNT_PATTERN = /^\d{1,8}(\.\d{1,2})?$/;
  public static PASSWORD_PATTERN =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#&^()_+])[A-Za-z\d@$!%*#&^()_+]{8,}$/;
  public static TWENTY_FIVE_MB_IN_BYTE = 25000000;
}

export const exportDataMapping: ExportDataMapping = {
  total_customer_premise_construction_costs: {
    sheetName: "Budget",
    cell: "F38",
  },
  total_isp_installation_costs: {
    sheetName: "Budget",
    cell: "F29",
  },
  total_osp_construction_costs: {
    sheetName: "Budget",
    cell: "F22",
  },
  total_engineering_project_management_costs: {
    sheetName: "Budget",
    cell: "F44",
  },
  project_total: {
    sheetName: "Budget",
    cell: "F55",
  },
  entity_name: {
    sheetName: "General Site Data",
    cell: "C6",
  },
  entity_application_number: {
    sheetName: "General Site Data",
    cell: "C7",
  },
  survey_company: {
    sheetName: "General Site Data",
    cell: "G6",
  },
  surveyor_name: {
    sheetName: "General Site Data",
    cell: "G7",
  },
  survey_date: {
    sheetName: "General Site Data",
    cell: "G8",
  },
  primary_contact_first_name: {
    sheetName: "General Site Data",
    cell: "C12",
  },
  address1: {
    sheetName: "General Site Data",
    cell: "C14",
  },
  address2: {
    sheetName: "General Site Data",
    cell: "C15",
  },
  city: {
    sheetName: "General Site Data",
    cell: "C16",
  },
  state: {
    sheetName: "General Site Data",
    cell: "C18",
  },
  zip: {
    sheetName: "General Site Data",
    cell: "C19",
  },
  email1: {
    sheetName: "General Site Data",
    cell: "C20",
  },

  applicant_company_name: {
    sheetName: "General Site Data",
    cell: "C24",
  },
  applicant_company_website: {
    sheetName: "General Site Data",
    cell: "C26",
  },
  project_manager_name: {
    sheetName: "General Site Data",
    cell: "C27",
  },
  project_manager_email: {
    sheetName: "General Site Data",
    cell: "C28",
  },
  project_manager_phone: {
    sheetName: "General Site Data",
    cell: "C29",
  },
  project_community_name: {
    sheetName: "General Site Data",
    cell: "C30",
  },
  isp_ap_type: { sheetName: "General Site Data", cell: "C34" },
  isp_network_type: {
    sheetName: "General Site Data",
    cell: "C31",
  },
  isp_relay_type: { sheetName: "General Site Data", cell: "C32" },
  isp_router_type: {
    sheetName: "General Site Data",
    cell: "C33",
  },
  fcc_registration_number_1: {
    sheetName: "General Site Data",
    cell: "C42",
  },
  tower_manufacturer_1: {
    sheetName: "General Site Data",
    cell: "C43",
  },
  tower_type_1: {
    sheetName: "General Site Data",
    cell: "C44",
  },
  tower_serial_no_1: {
    sheetName: "General Site Data",
    cell: "C45",
  },
  tower_height_1: {
    sheetName: "General Site Data",
    cell: "C46",
  },
  painted_1: {
    sheetName: "General Site Data",
    cell: "C47",
  },
  fcc_registration_number_2: {
    sheetName: "General Site Data",
    cell: "F42",
  },
  tower_manufacturer_2: {
    sheetName: "General Site Data",
    cell: "F43",
  },
  tower_type_2: {
    sheetName: "General Site Data",
    cell: "F44",
  },
  tower_serial_no_2: {
    sheetName: "General Site Data",
    cell: "F45",
  },
  tower_height_2: {
    sheetName: "General Site Data",
    cell: "F46",
  },
  painted_2: {
    sheetName: "General Site Data",
    cell: "F47",
  },
  fcc_registration_number_3: {
    sheetName: "General Site Data",
    cell: "G42",
  },
  tower_manufacturer_3: {
    sheetName: "General Site Data",
    cell: "G43",
  },
  tower_type_3: {
    sheetName: "General Site Data",
    cell: "G44",
  },
  tower_serial_no_3: {
    sheetName: "General Site Data",
    cell: "G45",
  },
  tower_height_3: {
    sheetName: "General Site Data",
    cell: "G46",
  },
  painted_3: {
    sheetName: "General Site Data",
    cell: "G47",
  },
  fcc_registration_number_4: {
    sheetName: "General Site Data",
    cell: "H42",
  },
  tower_manufacturer_4: {
    sheetName: "General Site Data",
    cell: "H43",
  },
  tower_type_4: {
    sheetName: "General Site Data",
    cell: "H44",
  },
  tower_serial_no_4: {
    sheetName: "General Site Data",
    cell: "H45",
  },
  tower_height_4: {
    sheetName: "General Site Data",
    cell: "H46",
  },
  painted_4: {
    sheetName: "General Site Data",
    cell: "H47",
  },
  fcc_registration_number_5: {
    sheetName: "General Site Data",
    cell: "I42",
  },
  tower_manufacturer_5: {
    sheetName: "General Site Data",
    cell: "I43",
  },
  tower_type_5: {
    sheetName: "General Site Data",
    cell: "I44",
  },
  tower_serial_no_5: {
    sheetName: "General Site Data",
    cell: "I45",
  },
  tower_height_5: {
    sheetName: "General Site Data",
    cell: "I46",
  },
  painted_5: {
    sheetName: "General Site Data",
    cell: "I47",
  },
  site_latitude_1: {
    sheetName: "General Site Data",
    cell: "C53",
  },
  site_longitude_1: {
    sheetName: "General Site Data",
    cell: "C54",
  },
  site_ground_elev_1: {
    sheetName: "General Site Data",
    cell: "C55",
  },
  site_latitude_2: {
    sheetName: "General Site Data",
    cell: "G53",
  },
  site_longitude_2: {
    sheetName: "General Site Data",
    cell: "G54",
  },
  site_ground_elev_2: {
    sheetName: "General Site Data",
    cell: "G55",
  },
  site_latitude_3: {
    sheetName: "General Site Data",
    cell: "H53",
  },
  site_longitude_3: {
    sheetName: "General Site Data",
    cell: "H54",
  },
  site_ground_elev_3: {
    sheetName: "General Site Data",
    cell: "H55",
  },
  site_latitude_4: {
    sheetName: "General Site Data",
    cell: "I53",
  },
  site_longitude_4: {
    sheetName: "General Site Data",
    cell: "I54",
  },
  site_ground_elev_4: {
    sheetName: "General Site Data",
    cell: "I55",
  },
  site_latitude_5: {
    sheetName: "General Site Data",
    cell: "J53",
  },
  site_longitude_5: {
    sheetName: "General Site Data",
    cell: "J54",
  },
  site_ground_elev_5: {
    sheetName: "General Site Data",
    cell: "J55",
  },

  revenue_retail_customers: {
    sheetName: "Sustainability",
    cell: "B8",
  },
  revenue_per_customer: {
    sheetName: "Sustainability",
    cell: "B9",
  },
  retail_revenue: {
    sheetName: "Sustainability",
    cell: "B10",
  },
  other_revenue: {
    sheetName: "Sustainability",
    cell: "B23",
  },
  total_revenue: {
    sheetName: "Sustainability",
    cell: "B24",
  },
  project_operational_cost: {
    sheetName: "Sustainability",
    cell: "E8",
  },
  project_financially_stable: {
    sheetName: "Sustainability",
    cell: "D14",
  },
  unique_application_name: {
    sheetName: "Cover Sheet",
    cell: "F5",
  },
  project_summary: {
    sheetName: "Project Plan",
    cell: "A7",
  },
  project_rights_easements: {
    sheetName: "Rights and Easements",
    cell: "A7",
  },
  project_resource_plan: {
    sheetName: "Resource Plan",
    cell: "A7",
  },
};

export const emailPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneNumberPattern = /^\d{10}$/;
