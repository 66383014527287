import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-hold-info',
  templateUrl: './hold-info.component.html',
  styleUrls: ['./hold-info.component.scss']
})
export class HoldInfoComponent implements OnInit {

  constructor(
  private modal: NgbModal

  ) { }

  ngOnInit(): void {

  }
cancel(){
  this.modal.dismissAll();
  
}
  

}
