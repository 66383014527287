import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableColumn } from 'src/app/shared/models/components.models';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input('availableColumns') availableColumns: Array<DatatableColumn> = [];
  @Input('visibleColumns') visibleColumns: Array<DatatableColumn> = [];
  @Output('selectedChange') selectedChange = new EventEmitter<
    Array<DatatableColumn>
  >();
  form!: FormArray;

  constructor(private modal: NgbModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  /**
   * Form
   */
  initForm = () => {
    this.form = this.fb.array(
      this.availableColumns.map((column) =>
        this.fb.group({
          checked:
            this.visibleColumns.findIndex((vCol) => vCol.name === column.name) >
            -1,
          name: column.name,
        })
      )
    );
  };

  get controls() {
    return this.form.controls;
  }

  /**
   * Emit selected fields
   */
  emit = () => {
    this.selectedChange.emit(this.selectedColumns);
    this.close();
  };

  /**
   * @returns selected columns
   */
  get selectedColumns() {
    return this.availableColumns.filter(
      (column, index) => this.asGroup(this.form.at(index)).value.checked
    );
  }

  /**
   * Return as form group
   */
  asGroup = (group: any) => group as FormGroup;

  /**
   * Close modal
   */
  close = () => this.modal.dismissAll();

  /**
   * Select all columns
   */
  selectAll = () => {
    this.setCheckedTo(true);
  };

  /**
   * Unselect all columns
   */
  unSelectAll = () => {
    this.setCheckedTo(false);
  };

  /**
   * Set all checkbox state
   * @param state
   */
  private setCheckedTo = (state: boolean) => {
    this.form.controls.forEach((column, index: number) => {
      (this.form.at(index) as FormGroup).controls.checked.setValue(state);
    });
  };
}
