<div class="container" *ngIf="file">
  <div class="file-holder">
    <a target="_blank" [href]="getFileRouteLink(file.url)">{{ file.title }}</a>
    <!-- <div>
      <img src="../../../assets/main/i/icons/16/lock.png" alt="" />
      <img
        src="../../../assets/main/css/themes/classic/images/icons/clock.png"
        alt=""
      />
    </div> -->
  </div>
  <ng-container *ngIf="!viewMode">
    <input #check (change)="deleteFile($event)" type="checkbox" />
    <label (click)="check.click()">Delete this file</label>
  </ng-container>
</div>
