import { forwardRef, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ButtonComponent } from "./components/button/button.component";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { SearchPipe } from "./pipe/search.pipe";
import { NgxMaskModule } from "ngx-mask";
import { RouterModule } from "@angular/router";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { ErrorPopupComponent } from "./components/error-popup/error-popup.component";
import { RequiredDirective } from "./directive/required.directive";
import { JqDatepickerDirective } from "./directive/jq-datepicker.directive";
import { NumberOnlyDirective } from "./directive/number-only.directive";
import { EyeballDirective } from "./directive/eyeball.directive";
import { UploadViewComponent } from "./components/upload-view/upload-view.component";
import { DateDirective } from "./directive/date.directive";
import { LettersOnlyDirective } from "./directive/letters-only.directive";
import { LettersWithApostropheDirective } from "./directive/letters-with-apostropha.directive";
import { AllowedPatternDirective } from "./directive/allowedPattern.directive";
import { AddressOnlyDirective } from "./directive/address.directive";
import { NameOnlyDirective } from "./directive/name.directive";
import { SidebarComponent } from "./common/sidebar/sidebar.component";
import { ErrorComponent } from "./common/error/error.component";
import { DatatableComponent } from "./components/datatable/datatable.component";
import { ModalActionBtnsComponent } from "./components/button/modal-action-btns/modal-action-btns.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { InputComponent } from "./components/form-fields/input/input.component";
import { CheckboxComponent } from "./components/form-fields/checkbox/checkbox.component";
import { TextareaComponent } from "./components/form-fields/textarea/textarea.component";
import { RadioComponent } from "./components/form-fields/radio/radio.component";
import { SelectComponent } from "./components/form-fields/select/select.component";
import { Select2Module } from "ng-select2-component";
import { NumbersOnlyInputDirective } from "./directive/onlynumberinput.directive";
import { FiltersComponent } from "./components/datatable/filters/filters.component";
import { MatCardModule } from "@angular/material/card";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { ShowInfoComponent } from './components/show-info/show-info.component';
import { FileViewComponent } from './components/file-view/file-view.component';
import { PdfViewerComponent, PdfViewerModule } from "ng2-pdf-viewer";
import { HoldInfoComponent } from './components/hold-info/hold-info.component';

@NgModule({
  declarations: [
    NumbersOnlyInputDirective,
    InputComponent,
    ButtonComponent,
    CheckboxComponent,
    TextareaComponent,
    RadioComponent,
    SelectComponent,
    SearchPipe,
    UploadFileComponent,
    ErrorPopupComponent,
    RequiredDirective,
    JqDatepickerDirective,
    NumberOnlyDirective,
    EyeballDirective,
    UploadViewComponent,
    DateDirective,
    LettersOnlyDirective,
    LettersWithApostropheDirective,
    AllowedPatternDirective,
    AddressOnlyDirective,
    NameOnlyDirective,
    SidebarComponent,
    ErrorComponent,
    DatatableComponent,
    ModalActionBtnsComponent,
    FiltersComponent,
    ShowInfoComponent,
    FileViewComponent,
    HoldInfoComponent,
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatToolbarModule,
    Select2Module,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    NgxMaskModule.forRoot(),
    NgbModule,
    NgbDropdownModule,
    RouterModule,
    MatCardModule,
    MatTooltipModule,
    NgxDatatableModule,
    MatProgressBarModule,
    PdfViewerModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline" },
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  exports: [
    NumbersOnlyInputDirective,
    InputComponent,
    ButtonComponent,
    CheckboxComponent,
    TextareaComponent,
    RadioComponent,
    SelectComponent,
    UploadFileComponent,
    ErrorPopupComponent,
    Select2Module,
    RequiredDirective,
    JqDatepickerDirective,
    NumberOnlyDirective,
    EyeballDirective,
    UploadViewComponent,
    DateDirective,
    LettersOnlyDirective,
    LettersWithApostropheDirective,
    AllowedPatternDirective,
    AddressOnlyDirective,
    NameOnlyDirective,
    MatRadioModule,
    SidebarComponent,
    ErrorComponent,
    DatatableComponent,
    ModalActionBtnsComponent,
    FiltersComponent,
    FileViewComponent,
    PdfViewerComponent
  ],
})
export class SharedModule {}
