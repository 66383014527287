<div class="row upload-file">
  <div class="col-4 col-s-3"></div>
  <div class="col-4 col-s-6">
    <div class="aside">
        <div class="center-div" style="text-align: center;">
            <h1>Upload Your Files</h1>
            <input type="file" id="multi" multiple />
            <br />
            <iframe
              src="https://giphy.com/embed/6036p0cTnjUrNFpAlr"
              width="50"
              height="50"
              frameborder="0"
              class="giphy-embed"
              allowfullscreen=""
            ></iframe>
        </div>
    </div>
  </div>
</div>
