import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { CookieModule } from "ngx-cookie";
import { NgSelectModule } from "@ng-select/ng-select";
import { InputMaskModule } from "@ngneat/input-mask";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToastrModule } from "ngx-toastr";
import { CanMoveResolver } from "./core/guards/can-move.resolver";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    CookieModule.forRoot(),
    NgSelectModule,
    InputMaskModule.forRoot({ inputSelector: "input", isAsync: true }),
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
  ],
  providers: [CanMoveResolver],
  bootstrap: [AppComponent],
})
export class AppModule {}
