import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "src/app/core/services/api.service";
import { CacheService } from "src/app/core/services/cache.service";
import { environment } from "src/environments/environment";

export interface UploadView {
  title: string;
  url: string;
  id: string;
  tableId?: number;
}

export interface EmitPayload {
  id: number;
  state: boolean;
  obs: Observable<any>;
}

export interface MarkToDelete {
  [id: number]: Observable<any>;
}

@Component({
  selector: "app-upload-view",
  templateUrl: "./upload-view.component.html",
  styleUrls: ["./upload-view.component.scss"],
})
export class UploadViewComponent implements OnInit {
  @Input("file") file: UploadView | null = null;
  @Input("tableName") tableName = "";
  @Input("viewMode") viewMode = false;
  @Output("onDelete") onDelete = new EventEmitter<any>();
  @Output("onReserve") onReserve = new EventEmitter<any>();
  @Output("onLog") onLog = new EventEmitter<any>();

  constructor(private api: ApiService, private cache: CacheService) {}

  ngOnInit(): void {}

  deleteFile = async (event: any) => {
    this.onDelete.emit({
      isChecked: event.srcElement.checked,
      file: this.file,
    });
    // await this.api
    //   .delete({
    //     ApplicationId: environment.applicationKey,
    //     ApplicationTableId: this.file?.tableId
    //       ? this.file?.tableId
    //       : await this.cache.findTable('Documentation'),
    //     Where: {
    //       Rid: +this.file!.id,
    //     },
    //   })
    //   .toPromise();
    // this.onDelete.emit({
    //   id: +(this.file?.id || 0),
    //   state: event.srcElement.checked,
    //   obs: this.api.delete({
    //     ApplicationId: environment.applicationKey,
    //     ApplicationTableId: this.file?.tableId
    //       ? this.file?.tableId
    //       : await this.cache.findTable("Documentation"),
    //     Where: {
    //       Rid: +this.file!.id,
    //     },
    //   }),
    // });
  };

  reserveFile = () => {
    this.onReserve.emit(this.file?.id);
  };

  logFile = () => {
    this.onReserve.emit(this.file?.id);
  };

  getFileRouteLink(url: string): string {
    if (!url) return "";
    const urlSplit = url.split("tables");
    if (urlSplit.length < 1) return "";
    return "file" + urlSplit[1];
  }
}
