<div class="modal-content">
  <div class="modal-header">
    <div class="modal-header-center">
      <h4 class="modal-title">Notification</h4>
    </div>
  </div>
  <div class="modal-body m-2">
    We are not accepting Grant Applications currently. You will be notified when
    the Grant Application opens.
  </div>
  <div
    class="modal-footer modal-footer-custom-new01 d-flex justify-content-between"
  >
    <div class="modal-footer-left">
      <button id="prev" class="btn alertButtonCancel" (click)="cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
