<div [formGroup]="form" class="form-group">
  <label>{{ label }}</label>
  <span
  *ngIf="(isRequired && label)"
  class="ml-1 mb-1 text-danger"
  >
  *
  </span>
  <div class="form-group-control">
    <textarea
      class="form-control"
      [formControlName]="controlName"
      [rows]="rows"
      [attr.disabled]="disabled ? true : null"
    ></textarea>
  </div>
</div>
